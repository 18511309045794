import axios from 'axios';
import { apiUrl, authUrl } from '../constants';

export const requestDemo = () => {
    window.location.href = "https://hello.truly.co/book-a-meeting";
};

export const whatIsRPA = () => {
    window.location.href = "/solutions/what-is-revenue-process-automation";
};

export const whatIsActivityIntelligence = () => {
    window.location.href = "/solutions/what-is-activity-intelligence";
};

export const howToEliminateDataEntry = () => {
    window.location.href = "/solutions/eliminate-sales-rep-data-entry";
};

export const byRole = () => {
    window.location.href = "/solutions/for-revenue-ops";
};

export const whatIsSalesProcessMining = () => {
    window.location.href = "/solutions/sales-process-optimization";
};

export const understandProcessEffectiveness = () => {
    window.location.href = "/solutions/sales-process-optimization";
};

export const salesLeaders = () => {
    window.location.href = "/solutions/for-sales-leaders";
};

export const revOps = () => {
    window.location.href = "/solutions/for-revenue-ops";
};

export const salesEnablement = () => {
    window.location.href = "/solutions/for-sales-enablement";
};

export const whatAreAIBots = () => {
    window.location.href = "/features/what-are-bots";
};

export const whatIsHyperautomation = () => {
    window.open("https://blog.truly.co/2022/07/07/what-is-sales-hyperautomation/");
};


export const trulyApi = axios.create({
    baseURL: apiUrl,
    responseType: 'json',
    withCredentials: true
});

export const authApi = axios.create({
    baseURL: authUrl,
    responseType: 'json',
    withCredentials: true
});