import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Waypoint } from 'react-waypoint';
import { Helmet } from 'react-helmet';
import { ThemeProvider, trulyTheme } from 'js-components';
import Header from '../Header';
import Footer from '../Footer';
import Favicon from './favicon.ico';
import { mobileBreakpoint } from '../../constants';

const GlobalStyles = createGlobalStyle`
  a {
    text-decoration: none;
    cursor: pointer;
  }

  * { box-sizing: border-box; }
  body {
    margin: 0;
    font-family: lato;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    overflow: auto;

    @media (max-height: 600px) {
      align-items: initial;
    }
  }

  h1 {
    margin: 0;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
  }
`;

let lottie;

export default class TemplateWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      isMenuOpen: false,
      showHeaderShadow: false
    };
  }

  componentDidMount() {
    if (!window.lottie) {
      lottie = require('lottie-web');
      window.lottie = lottie;
    }
  }

  toggleMenu = newState => {
    this.setState({ isMenuOpen: newState });
  };

  render() {
    const { children } = this.props;
    const { isMenuOpen, showHeaderShadow } = this.state;

    return (
      <ThemeProvider
        theme={{
          ...trulyTheme,
          breakpoints: ['50rem', '60rem']
        }}
      >
        <div>
          <GlobalStyles />
          <Helmet
            htmlAttributes={{
              lang: 'en'
            }}
            title="Truly"
          >
            {this.props.title && <title />}
            <link href="https://fonts.googleapis.com/css?family=Josefin+Sans:400,700&display=swap" rel="stylesheet" />
            <link rel="shortcut icon" href={Favicon} />
          </Helmet>
          <div>
            <BlurWrapper id="blurWrapper">
              <Header isMenuOpen={isMenuOpen} onToggleMenu={this.toggleMenu} showHeaderShadow={showHeaderShadow} />
              <Waypoint
                onLeave={() => this.setState({ showHeaderShadow: true })}
                onEnter={() => this.setState({ showHeaderShadow: false })}
              />
              {!isMenuOpen && <MainWrapper>{children}</MainWrapper>}
              <Footer />
            </BlurWrapper>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

const BlurWrapper = styled.div`
  transition: filter 0.1s ease-in-out;
  &.blur {
    filter: blur(8px);
  }
`;

const MainWrapper = styled.div`
  padding-top: 80px;
  @media (max-width: ${mobileBreakpoint}) {
    padding-top: 56px;
  }
`;
