import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'js-components'
import { mobileBreakpoint } from '../../constants'

function BurgerButton({ isOpen, onClick }) {
  return (
    <Button isOpen={isOpen} onClick={onClick}>
      <Bar />
      <Bar />
      <Bar />
      <Bar />
    </Button>
  )
}

BurgerButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
}

export default BurgerButton

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 1.125rem;
  outline: none;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 1.5rem;

  div:nth-child(1) {
    top: 0;
  }

  div:nth-child(2),
  div:nth-child(3) {
    top: 0.5rem;
  }

  div:nth-child(4) {
    top: 1rem;
  }

  ${props =>
    props.isOpen &&
    `
      div:nth-child(1),
      div:nth-child(4) {
        left: 50%;
        top: 0.375rem;
        width: 0%;
      }
      div:nth-child(2) {
        transform: rotate(45deg);
      }

      div:nth-child(3) {
        transform: rotate(-45deg);
      }
    `};

  @media (min-width:${mobileBreakpoint}) {
    display: none;
  }
`

const Bar = styled.div`
  background: ${colors.trulyBlue};
  border-radius: 0.125rem;
  display: block;
  height: 0.125rem;
  left: 0;
  opacity: 1;
  position: absolute;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  width: 100%;
`
