import React, { Component } from 'react'
import styled from 'styled-components'
import {
  Micro,
  Large,
  TextInput,
  Select,
  colors,
  Button,
} from 'js-components'

const defaultState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  crm: '',
  numberOfEmployees: '',
  useCase: '',
  companyName: '',
  firstNameError: null,
  lastNameError: null,
  emailError: null,
  phoneError: null,
  companyError: null,
  buttonDisable: true,
  loading: false,
}

export default class SignUpForm extends Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  handleOnChangeInput(e) {
    const name = e.name
    const value = e.value
    this.setState({ [name]: value })
  }

  handleOnBlur(e) {
    const name = e.name
    const value = e.value
    this.validateField(name, value)
  }

  validateField(name, value) {
    let error = null

    if (value.trim() === '') {
      error = 'Field is required'
    } else {
      switch (name) {
        case 'firstName':
          break
        case 'lastName':
          break
        case 'email':
          const isValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
          error = isValid ? null : 'Invalid email'
          break
        case 'phone':
          break
        case 'companyName':
          break
        default:
          break
      }
    }

    this.setState({ [`${name}Error`]: error })
  }

  isButtonDisabled() {
    const {
      firstName,
      lastName,
      phone,
      email,
      companyName,
      companyError,
      crm,
      numberOfEmployees,
      useCase,
      firstNameError,
      lastNameError,
      phoneError,
      emailError,
    } = this.state

    const formIsEmpty =
      firstName === '' ||
      lastName === '' ||
      phone === '' ||
      email === '' ||
      crm === '' ||
      numberOfEmployees === '' ||
      useCase === '' ||
      companyName === ''

    const hasErrors =
      firstNameError !== null ||
      lastNameError !== null ||
      phoneError !== null ||
      emailError !== null ||
      companyError != null

    return formIsEmpty || hasErrors
  }

  render() {
    const {
      firstName,
      lastName,
      phone,
      email,
      companyName,
      numberOfEmployees,
      useCase,
      crm,
      firstNameError,
      lastNameError,
      phoneError,
      emailError,
      loading,
    } = this.state

    return (
      <Form
        id="contact"
        action="http://www2.truly.co/l/311261/2018-07-27/81kpv"
      >
        <Large color={colors.lightGray}>REQUEST A DEMO</Large>
        <FormLabel>First Name</FormLabel>
        <TextInput
          placeholder="First Name"
          value={firstName}
          name="firstName"
          error={firstNameError}
          onChange={(value, e) => this.handleOnChangeInput(e)}
          onBlur={e => this.handleOnBlur(e)}
        />
        <FormLabel>Last Name</FormLabel>
        <TextInput
          placeholder="Last Name"
          value={lastName}
          name="lastName"
          error={lastNameError}
          onChange={(value, e) => this.handleOnChangeInput(e)}
          onBlur={e => this.handleOnBlur(e)}
        />
        <FormLabel>Phone Number</FormLabel>
        <TextInput
          placeholder="Phone Number"
          value={phone}
          name="phone"
          error={phoneError}
          onChange={(value, e) => this.handleOnChangeInput(e)}
          onBlur={e => this.handleOnBlur(e)}
        />
        <FormLabel>E-Mail</FormLabel>
        <TextInput
          placeholder="E-Mail"
          value={email}
          name="email"
          error={emailError}
          onChange={(value, e) => this.handleOnChangeInput(e)}
          onBlur={e => this.handleOnBlur(e)}
        />
        <FormLabel>Company Name</FormLabel>
        <TextInput
          placeholder="Company Name"
          value={companyName}
          name="companyName"
          onChange={(value, e) => this.handleOnChangeInput(e)}
          onBlur={e => this.handleOnBlur(e)}
        />
        <FormLabel>Number of Employees</FormLabel>
        <Select
          value={numberOfEmployees}
          name="numberOfEmployees"
          onChange={(value, e) => this.handleOnChangeInput(e)}
          onBlur={e => this.handleOnBlur(e)}
          options={[{ value: "", label: "Select a Company Size" }, { value: "1-20", label: "1-20" }, { value: "21-50", label: "21-50" }, { value: "51-100", label: "51-100" }, { value: "101-500", label: "101-500" }, { value: "501-1000", label: "501-1000" }, { value: "1001+", label: "1001+" }]}
        >
        </Select>
        <FormLabel>Use Case</FormLabel>
        <Select
          value={useCase}
          name="useCase"
          onChange={(value, e) => this.handleOnChangeInput(e)}
          onBlur={e => this.handleOnBlur(e)}
          options={[{ value: "", label: "Select a Use Case" }, { value: "Sales", label: "Sales" }, { value: "Support", label: "Support" }, { value: "Both Sales and Support", label: "Both Sales and Support" }]}
        >
        </Select>
        <FormLabel>CRM</FormLabel>
        <Select
          value={crm}
          name="crm"
          onChange={(value, e) => this.handleOnChangeInput(e)}
          onBlur={e => this.handleOnBlur(e)}
          options={[{ value: "", label: "Select your CRM" }, { value: "Salesforce", label: "Salesforce" }, { value: "Zendesk", label: "Zendesk" }, { value: "Other", label: "Other" }]}
        >
        </Select>

        <SubmitButton
          color={colors.trulyBlue}
          height={43}
          disabled={this.isButtonDisabled()}
          loading={loading}
          value="submit"
          round
          size="regular"
        />
      </Form>
    )
  }
}

const Form = styled.form`
  padding: 2rem 2rem 1rem 2rem;
  width: 100%;
  text-align: left;
  font-family: 'Josefin Sans';
`

const SubmitButton = styled(Button)`
  margin: 2rem auto 1rem;
  width: 100%;
`

const FormLabel = styled(Micro)`
  color: ${colors.lightGray};
  text-transform: uppercase;
  padding-bottom: 4pt;
  padding-top: 8pt;
`
