import React from 'react';
import { navigate } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import { Title2, Logo, Button, colors, Flex, Box, Caret, Small, Regular, GlobalStyle } from 'js-components';
import BurgerButton from '../BurgerButton';
import Modal from '../Modal';
import { SignUpFormCard } from '../SignUp';
import { SignInFormCard } from '../SignIn';
import { mobileBreakpoint } from '../../constants';
import { requestDemo } from '../../common/Util';

const dropdownsInitialState = {
  concept: false,
  solution: false,
  product: false,
  resources: false
};

export default class HeaderComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      ...dropdownsInitialState
    };
  }

  toggleCollapse = key => {
    this.setState({ ...dropdownsInitialState, [key]: !this.state[key] });
  };

  resetDropdowns = () => {
    this.setState(dropdownsInitialState);
  };

  closeMenu = () => {
    this.props.onToggleMenu(false);
  };

  closeMenuAndResetDropdowns = () => {
    this.resetDropdowns();
    this.closeMenu();
  };

  to = path => {
    this.closeMenuAndResetDropdowns();
    navigate(path);
  };

  openInNewTab = path => {
    this.closeMenuAndResetDropdowns();
    window.open(path,'_blank');
  };

  renderSolutions = () => {
    return (
      <Flex flexDirection={['column', 'column']}>
        <NavColumn>


          <SubMenuLabel>Automation Solutions</SubMenuLabel>

          <SubMenuLink as="a" onClick={() => this.to('/solutions/automate-linkedin-prospecting')}>
            Automated Prospecting
          </SubMenuLink>        
          <SubMenuLink as="a" onClick={() => this.to('/solutions/better-than-a-contact-data-provider')}>
            Enrich & Validate Contacts
          </SubMenuLink>        
          <SubMenuLink as="a" onClick={() => this.to('/solutions/automate-lifecycle-marketing')}>
            Segmentation & Lifecycle
          </SubMenuLink> 
          <SubMenuLink as="a" onClick={() => this.to('/solutions/eliminate-sales-rep-data-entry')}>
            CRM Data Entry
          </SubMenuLink>        
          <SubMenuLink as="a" onClick={() => this.to('/solutions/mine-your-sales-activity-data')}>
            Process Analytics
          </SubMenuLink>
          <SubMenuLink as="a" onClick={() => this.to('/solutions/account-opportunity-health-scoring')}>
            Deal & Customer Health Monitoring
          </SubMenuLink>
          <SubMenuLink as="a" onClick={() => this.to('/solutions/guided-automated-selling')}>
            Guided Selling
          </SubMenuLink>
          <SubMenuLink as="a" onClick={() => this.to('/solutions/monitor-and-fix-data-quality')}>
            Monitor & Fix Data Quality
          </SubMenuLink>
          <br/>
          <SubMenuLabel>By Role</SubMenuLabel>
          <SubMenuLink as="a" onClick={() => this.to('/solutions/for-revenue-ops')}>
            For Revenue Operations
          </SubMenuLink>
          <SubMenuLink as="a" onClick={() => this.to('/solutions/for-analytics-teams/')}>
            For Analytics Teams
          </SubMenuLink>
          <SubMenuLink as="a" onClick={() => this.to('/solutions/for-sales-leaders')}>
            For Sales
          </SubMenuLink>
          <SubMenuLink as="a" onClick={() => this.to('/solutions/for-marketing')}>
            For Marketing
          </SubMenuLink>
<br/>



        </NavColumn>       
      </Flex>
    );
  };

  renderProducts = () => {
    return (
      <Flex flexDirection="column">

        <SubMenuLabel>Bot Platform</SubMenuLabel>
        <SubMenuLink as="a" onClick={() => this.to('/features/advanced-text-parsing')}>
          Rules Engine
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/features/topic-extraction')}>
          No-Code AI
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/features/whoidwhatid-autocomplete')}>
          Automated Execution
        </SubMenuLink>

        <br/>


       <SubMenuLabel> Bot Gallery</SubMenuLabel>
        <SubMenuLink as="a" onClick={() => this.to('/features/what-are-bots')}>
          Bots Overview
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/features/lead-match-bot')}>
          Linkedin
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/features/sequence-reply-bot')}>
          Lifecycle Marketing
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/features/whoidwhatid-autocomplete')}>
          CRM Updater
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/features/call-coaching-bot')}>
          Coaching
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/features/stage-validator-bot')}>
          Pipeline Management
        </SubMenuLink>

        <br/>

        <SubMenuLabel>Activity Tracking</SubMenuLabel>
       <SubMenuLink as="a" onClick={() => this.to('/features/email-sync-to-salesforce')}>
          Email
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/features/zoom-meetings')}>
          Meeting Tracking & Recording
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/features/desktop-app')}>
          Phone System
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/features/local-presence')}>
          Sales Dialer
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/features/text-messaging-sms')}>
          Text & MMS Messaging
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/features/mobile-apps')}>
          Mobile App
        </SubMenuLink>

        <br/>        

        <SubMenuLabel>Features</SubMenuLabel>
        <SubMenuLink as="a" onClick={() => this.to('/features/what-are-bots')}>
          All Features
        </SubMenuLink>


        
      </Flex>
    );
  };

  renderResources = () => {
    return (
      <Flex flexDirection="column">
        <SubMenuLink as="a" onClick={() => this.to('/about')}>
          Automation Academy
        </SubMenuLink>
        <SubMenuLink as="a" onClick={() => this.to('/careers')}>
          AutomationHeads Community
        </SubMenuLink>
        <SubMenuLink as="a" href="https://blog.truly.co/">
          Blog
        </SubMenuLink>
        <SubMenuLink as="a" href="https://blog.truly.co/">
          CheatSheets
        </SubMenuLink>        
      </Flex>
    );
  };

  renderConcept = () => {
    return (
      <Flex flexDirection="column">
          <SubMenuLabel>Concept Overview</SubMenuLabel>
          <SubMenuLink as="a" onClick={() => this.openInNewTab('https://blog.truly.co/2022/07/07/what-is-sales-hyperautomation/')}>
            What is Hyperautomation?
          </SubMenuLink>        
          <SubMenuLink as="a" onClick={() => this.to('/features/what-are-bots')}>
            What are AI Enabled Bots?
          </SubMenuLink>
          <SubMenuLink as="a" onClick={() => this.to('/solutions/hyperautomation-vs-sales-tools')}>
            How is This Different Than Sales Tools?
          </SubMenuLink>   

          <br/>

          <SubMenuLabel>Resources</SubMenuLabel>
          <SubMenuLink as="a" onClick={() => this.openInNewTab('https://revops-academy.truly.co')}>
            Automation Academy
          </SubMenuLink>        
          <SubMenuLink as="a" onClick={() => this.openInNewTab('https://hello.truly.co/automation-heads-signup')}>
            Automation Heads Community
          </SubMenuLink>
          <SubMenuLink as="a" onClick={() => this.openInNewTab('https://www.linkedin.com/newsletters/automation-heads-weekly-6975403585060687872/')}>
            Newsletter
          </SubMenuLink>   
          <SubMenuLink as="a" onClick={() => this.openInNewTab('https://blog.truly.co/')}>
            Blog
          </SubMenuLink>   
      </Flex>
    );
  };

  renderDropdown = ({ caption, isOpen, key, content }) => {
    return (
      <div>
        <Flex pb={1} alignItems="center" onClick={() => this.toggleCollapse(key)}>
          <Title2 color={colors.white}>{caption}</Title2>
          <Caret orientation={isOpen ? 'top' : 'bottom'} />
        </Flex>
        {isOpen && (
          <Box pt={2} pb={3}>
            {content()}
          </Box>
        )}
      </div>
    );
  };

  renderMobileNav = () => {
    const { isMenuOpen } = this.props;
    const { solution, product, resources, concept } = this.state;

    return (
      <NavMobile width={1} flexDirection="column" isOpen={isMenuOpen}>
        <Flex flexDirection="column" flex={1} width={1}>
          {this.renderDropdown({
            caption: 'concept',
            isOpen: concept,
            key: 'concept',
            content: this.renderConcept
          })}

          {this.renderDropdown({
            caption: 'Solutions',
            isOpen: solution,
            key: 'solution',
            content: this.renderSolutions
          })}

          {this.renderDropdown({
            caption: 'Product',
            isOpen: product,
            key: 'product',
            content: this.renderProducts
          })}

          <Title2 bold as="a" pb={1} color={colors.white} onClick={() => this.to('/pricing/')}>
            Pricing
          </Title2>


          <Title2 bold as="a" pb={1} color={colors.white} onClick={() => this.to('/careers/')}>
            Careers
          </Title2>

          <Title2 bold as="a" pb={1} color={colors.white} onClick={requestDemo}>
            Request a Demo
          </Title2>
        </Flex>
      </NavMobile>
    );
  };

  renderDropdownDesktop = ({ caption, isOpen, key, content }) => {
    return (
      <MenuItem
        onClick={() => this.toggleCollapse(key)}
      >
        <Regular px={2} color={colors.white}>
          {caption}
        </Regular>
        <NavCaret orientation={isOpen ? 'top' : 'bottom'} />
        {isOpen && <FloatingPanel>{content()}</FloatingPanel>}
      </MenuItem>
    );
  };

  renderDesktopNav = () => {
    const { isMenuOpen } = this.props;
    const { solution, product, resources, concept } = this.state;

    return (
      <NavDesktop isOpen={isMenuOpen}>
        {this.renderDropdownDesktop({
          caption: 'Hyperautomation',
          isOpen: concept,
          key: 'concept',
          content: this.renderConcept
        })}

        {this.renderDropdownDesktop({
          caption: 'Solutions',
          isOpen: solution,
          key: 'solution',
          content: this.renderSolutions
        })}

        {this.renderDropdownDesktop({
          caption: 'Product',
          isOpen: product,
          key: 'product',
          content: this.renderProducts
        })}

        <Regular as="a" px={2} color={colors.white} onClick={() => this.to('/pricing/')}>
          Pricing
        </Regular>

        <Modal
          contentLabel="Sign In"
          hideCloseButton
          button={({ onClick }) => (
            <Regular as="a" pl={2} pr={3} color={colors.white} onClick={onClick}>
              Log In
            </Regular>
          )}
          modalContent={({ onRequestClose }) => <SignInFormCard onClose={onRequestClose} />}
        />
        <Modal
          contentLabel="Request a Demo"
          button={({ onClick }) => (
            <Button
              type="outline"
              size="medium"
              color={colors.white}
              round
              value="Request a Demo"
              onClick={requestDemo}
              height={43}
            />
          )}
          modalContent={({ onRequestClose }) => <SignUpFormCard onRequestClose={onRequestClose} />}
        />
      </NavDesktop>
    );
  };
  render() {
    const { isMenuOpen, onToggleMenu, showHeaderShadow } = this.props;
    return (
      <Header showShadow={showHeaderShadow}>
        <GlobalStyle />
        <HeaderWrapper>
          <Box mt="0.5rem">
            <a href="/">
              <Logo badgeColor={colors.white} textColor={colors.white} />
            </a>
          </Box>
          <BurgerPosition>
            <BurgerButton isOpen={isMenuOpen} onClick={() => onToggleMenu(!isMenuOpen)} />
          </BurgerPosition>
        </HeaderWrapper>
        {this.renderMobileNav()}
        {this.renderDesktopNav()}
      </Header>
    );
  }
}

const Header = styled.header`
  font-family: 'Josefin Sans';
  align-items: center;
  background-color: #222;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  transition: box-shadow 0.2s ease-in-out;
  ${props => props.showShadow && `box-shadow: 0 0.25rem 0.75rem ${colors.rgba(colors.white, 0.0)}`};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  position: fixed;
  width: 100%;
  z-index: 10;

  @media (max-width: ${mobileBreakpoint}) {
    flex-direction: column;
  }
`;

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  height: 4rem;
  justify-content: space-between;

  @media (max-width: ${mobileBreakpoint}) {
    width: 100%;
    justify-content: center;
  }
`;

const BurgerPosition = styled.div`
  position: absolute;
  right: 1rem;
`;

const NavColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16pt 0 0;
`;

const NavMobile = styled(Flex)`
  transition: opacity 2s ease-in-out;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  height: ${props => (props.isOpen ? 'calc(100vh - 5rem)' : 0)};
  transition: opacity 0.25s ease-in-out;

  ${props =>
    !props.isOpen &&
    `
      div:nth-child(2),
      div > * { display: none }
    `};

  @media (min-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

const NavCaret = styled(Caret)`
margin-left: -10px;
margin-top: -2px;
transition: transform 0.5s;
`;

const NavDesktop = styled(Flex)`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

const MenuItem = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const duration = '.2s';

function floatingPanelEntry() {
  const rotation = keyframes`
    0% {
      transform: translateX(-50%) translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateX(-50%) translateY(0px);
      opacity: 1;
    }
  `;
  return rotation;
}

const FloatingPanel = styled(Flex)`
  background-color: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(4px);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  left: 50%;
  top: 100%;
  list-style: none;
  margin: 0;
  padding: 1.75rem 2.25rem;
  position: absolute;
  z-index: 10;
  transform-origin: top;
  transform: translateX(-50%);
  animation: ${floatingPanelEntry()} ${duration};
`;

const SubMenuLabel = styled(Small)`
  color: ${colors.trulyBlue};
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 6px;
`;

const SubMenuLink = styled(Regular)`
  color: ${colors.white};
  padding-top: 4px;
  padding-bottom: 4px;
  white-space: nowrap;
  opacity: 0.8;
  -webkit-font-smoothing: antialiased;
  transition: 0.2s opacity;
  &:hover {
    opacity: 1;
    transition 0.2s opacity;
  }
`;
