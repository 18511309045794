import React from 'react';
import { ThemeProvider, trulyTheme, LoginModal } from 'js-components';
import trulyApiFactory from 'truly-api';
import { signUpUrl, authUrl } from '../../constants';

import { trulyApi, authApi } from '../../common/Util';
import { useLoginFlow, useNumberChooser, objectToQueryParamString } from 'truly-utils';

const apiClient = trulyApiFactory({
  axios: trulyApi
});

const authClient = trulyApiFactory({
  axios: authApi
});

const SignInFormCard = ({ onClose }) => {
  const { loading, loginFlowCallbacks, step, userEmail, defaultParams } = useLoginFlow(
    {
      me: apiClient.accounts.me,
      gatekeeper: apiClient.me.getGatekeeperSettings,
      forgotPassword: authClient.login.forgotPasssword,
      updateAccountWithDetails: apiClient.login.updateAccountWithDetails,
      updatePassword: apiClient.login.updatePassword,
      purchasePhoneNumber: apiClient.lg.purchasePhoneNumber,
      assignPhoneNumber: apiClient.lg.assignPhoneNumber,
      signupViaEmail: authClient.login.signupEmail,
      updateCompanyName: apiClient.lg.updateCompanyName,
      validatePassword: authClient.accounts.validatePassword
    },
    {
      redirectToSignup: true,
      signUpUrl,
      startOAuth: (url, queryParams) => {
        window.location.href = `${url}?${objectToQueryParamString(queryParams)}`;
        return false;
      },
      trulyAuthUrl: authUrl,
      allowSignup: true,
      onError: msg => {
        alert(`An error occurred: ${msg}`);
      }
    }
  );

  const { numberChooser } = useNumberChooser({
    searchPhoneNumbers: apiClient.lg.searchPhoneNumber
  });

  return (
    <ThemeProvider theme={trulyTheme}>
      <div>
        <LoginModal
          loading={loading}
          defaultParams={defaultParams}
          step={step}
          loginFlowCallbacks={loginFlowCallbacks}
          numberChooser={numberChooser}
          userEmail={userEmail}
          allowSignup
          closable
          onClose={onClose}
        />
      </div>
    </ThemeProvider>
  );
};

export default SignInFormCard;
