import styled from 'styled-components'
import { colors } from 'js-components'
import { Box } from 'js-components'

const Card = styled(Box)`
  align-items: center;
  background-color: ${props => props.color || colors.white};
  border-radius: 0.5rem;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;

  ${props =>
    props.shadow &&
    `box-shadow: 0 0.25rem 4rem ${colors.rgba(colors.trulyDark, 0.2)};`};
`

export default Card
