import React from 'react';
import styled from 'styled-components';
import { Flex, Logo, Micro, Small, Large, colors } from 'js-components';
import { Section } from '../Wrapper';
import { mobileBreakpoint } from '../../constants';

import californiaVector from './californiaVector.svg';

const FooterComponent = () => (
  <footer>
    <TopFooter>
      <Section flexDirection="row" alignItems="flex-start">
        <a href="/">
          <Logo width="37px" height="35px" badge badgeColor={colors.white} />
        </a>
        <Flex flexWrap="wrap" flexDirection={['column', 'row']} alignItems="baseline" justifyContent="flex-start" ml={['1rem', '5rem']}>
          <Column>
            <Micro color="white" mb="0.5rem">
              Company
            </Micro>
            <LinkLarge mb="0.5rem" as="a" href="/careers">
              Careers
            </LinkLarge>
            <LinkLarge mb="0.5rem" as="a" href="/privacy">
              Privacy policy
            </LinkLarge>
            <LinkLarge mb="0.5rem" as="a" href="/compliance">
              Compliance
            </LinkLarge>
            <LinkLarge mb="0.5rem" as="a" href="/terms">
              Terms of use
            </LinkLarge>
          </Column>
          <Column>
            <Micro color="white" mb="0.5rem">
              Get our apps
            </Micro>
            <LinkLarge
              mb="0.5rem"
              as="a"
              href="/signup/#/download"
            >
              Desktop
            </LinkLarge>
            <LinkLarge
              mb="0.5rem"
              as="a"
              href="/signup/#/mobile-app"
            >
              iOS
            </LinkLarge>
            <LinkLarge
              mb="0.5rem"
              as="a"
              href="/signup/#/mobile-app"
            >
              Android
            </LinkLarge>
            <LinkLarge
              mb="0.5rem"
              as="a"
              href="/signup/#/chrome-extension"
            >
              Chrome
            </LinkLarge>
          </Column>
          <Column>
            <Micro color="white" mb="0.5rem">
              Resources
            </Micro>
            <LinkLarge mb="0.5rem" as="a" href="https://revops-academy.truly.co">
              Automation Academy
            </LinkLarge>
            <LinkLarge mb="0.5rem" as="a" href="https://hello.truly.co/automation-heads-signup">
              Community
            </LinkLarge>
            <LinkLarge mb="0.5rem" as="a" href="https://www.linkedin.com/newsletters/automation-heads-weekly-6975403585060687872/">
              Newsletter
            </LinkLarge>          
            <LinkLarge mb="0.5rem" as="a" href="https://blog.truly.co">
              Blog
            </LinkLarge>          
            </Column>
          <Column>
            <Micro color="white" mb="0.5rem">
              Connect
            </Micro>
            <LinkLarge
              mb="0.5rem"
              as="a"
              target="_blank"
              rel="noopener"
              href="https://www.linkedin.com/company/trulyco/"
            >
              LinkedIn
            </LinkLarge>
            <LinkLarge
              mb="0.5rem"
              as="a"
              target="_blank"
              rel="noopener"
              href="https://twitter.com/truly_co"
            >
              Twitter
            </LinkLarge>
            <LinkLarge
              mb="0.5rem"
              as="a"
              target="_blank"
              rel="noopener"
              href="https://www.facebook.com/trulywireless"
            >
              Facebook
            </LinkLarge>
            <LinkLarge
              mb="0.5rem"
              as="a"
              target="_blank"
              rel="noopener"
              href="https://blog.truly.co"
            >
              Blog
            </LinkLarge>
          </Column>
        </Flex>
      </Section>
    </TopFooter>
    <BottomFooter>
      <Section flexDirection={['column', 'row']} p="1rem 0 1.25rem 0">
        <Small>Copyright Truly 2020</Small>
        <Flex justifyContent="center" alignItems="center">
          <CaliforniaImg src={californiaVector} alt="California" />
          <Small>604 Mission Street, Suite 200 San Francisco CA</Small>
        </Flex>
      </Section>
    </BottomFooter>
  </footer>
);

export default FooterComponent;

const TopFooter = styled(Flex)`
  background-color: #424242;
  font-family: 'Josefin Sans';
`;

const BottomFooter = styled(Flex)`
  background-color: #212324;
  color: ${colors.rgba(colors.white, 0.3)};
  font-family: 'Josefin Sans';
`;

const Column = styled.div`
  align-items: baseline;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  margin-right: 2rem;
  margin-bottom: 1rem;
  display: none;

  @media (min-width: ${mobileBreakpoint}) {
    margin-right: 5rem;
    margin-bottom: 0;
    display: flex;
  }
`;

const LinkLarge = styled(Large)`
  color: ${colors.rgba(colors.white, 0.6)};
  font-size: 1rem;
  font-weight: 300;
  text-decoration: none;
  transition: 0.3s color;

  :hover {
    color: ${colors.rgba(colors.white, 1.0)};
    transition: 0.3s color;
  }
`;

const CaliforniaImg = styled.img`
  visibility: hidden;

  @media (min-width: ${mobileBreakpoint}) {
    padding: 0 0.5rem;
    visibility: visible;
  }
`;
