import React from 'react'
import { ThemeProvider, trulyTheme } from 'js-components'
import Card from '../Card'
import SignUpForm from './Form'
import styled from 'styled-components'

const SignUpFormCard = ({ onRequestClose }) => (
  <ThemeProvider theme={trulyTheme}>
    <WidthCard shadow>
      <SignUpForm onRequestClose={onRequestClose} />
    </WidthCard>
  </ThemeProvider>
)

export default SignUpFormCard

const WidthCard = styled(Card)`
  margin: 0 auto;
`
